import {
  ErrorNotifier,
  errorDataBuilder,
  errorDataBuilderV2,
} from "../../../api/src/utils/error-notifier";

import { Cart } from "./types";
import { PROMOTIONS_BANNER_TYPES } from "../../utils/constants";

import { cartFromBigCommerceCart } from "../../transformers/from-big-commerce";
import { logError } from "../../utils/logger";
import vinoFetch from "../../utils/vinoFetch";

const bigCommerceStoreUrl = process.env.GATSBY_CHECKOUT_API_URL;
const checkoutRedirectUrlApi = `${bigCommerceStoreUrl}/checkout-redirect-url`;

interface CartHandlerResponse {
  error?: any;
  cart?: Cart;
}

interface CheckoutUrlHandlerResponse {
  checkoutUrl?: string;
  error?: Error;
}

interface CartHandler {
  addCartItem: ({
    customerId,
    cartId,
    cartItemId,
    accessToken,
    offer,
    geoLocRegion,
  }) => Promise<CartHandlerResponse>;
  createCart: ({
    customerId,
    productId,
    offer,
    geoLocRegion,
  }) => Promise<CartHandlerResponse>;
  updateGuestCartEmail: ({ cartId, email }) => Promise<CartHandlerResponse>;
  destroyCart: ({ cartId }) => Promise<CartHandlerResponse>;
  checkCart: ({ cartId, geoLocRegion }) => Promise<CartHandlerResponse>;
  loadCart: ({
    cartId,
    customerId,
    geoLocRegion,
  }) => Promise<CartHandlerResponse>;
  loadCheckoutUrl: ({
    userId,
    accessToken,
    email,
    cartId,
  }: {
    userId?: string;
    accessToken: string;
    email?: string;
    cartId?: string;
  }) => Promise<CheckoutUrlHandlerResponse>;
  mergeCarts: ({
    cartIds,
    cartId,
    geoLocRegion,
  }: {
    cartIds: Array<string>;
    cartId?: string;
    geoLocRegion?: string;
  }) => Promise<CartHandlerResponse>;
  removeCartItem: ({
    cartId,
    cartItemId,
    geoLocRegion,
  }) => Promise<CartHandlerResponse>;
  updateCartItem: ({
    cartId,
    cartItemId,
    productId,
    quantity,
    geoLocRegion,
  }) => Promise<CartHandlerResponse>;
  addCoupon: ({
    cartId,
    couponCode,
    accessToken,
    geoLocRegion,
  }) => Promise<CartHandlerResponse>;
  removeCoupon: ({
    cartId,
    couponCode,
    geoLocRegion,
  }) => Promise<CartHandlerResponse>;
  isFreeShippingApplied: (any) => Promise<boolean>;
  getPromotionByCode: (couponCode) => Promise<any>;
}

export const checkInventoryLevel = async (cart: any) => {
  if (!cart?.lineItems) return cart;

  const ids = cart?.lineItems.map((i) => i.productId.toString()).toString();

  try {
    const { data, status } = await vinoFetch.get("/api/inventory-levels", {
      params: { ids },
    });
    if (status === 200 && data.length) {
      const soldoutID = [];
      const updatedPhysicalItems = cart?.lineItems.map((i) => {
        const isSoldOut = data.some(
          (o) => o.id === i.productId && o.inventory_level === 0
        );

        if (isSoldOut) soldoutID.push(i.productId);

        const availableStock = data.find(
          (v) => v.id === i.productId && v.inventory_level < i.quantity
        );

        const currentStock = data.find((v) => v.id === i.productId);
        return {
          ...i,
          currentStock: currentStock.inventory_level,
          availableStock: availableStock
            ? availableStock.inventory_level
            : null,
          isSoldOut: isSoldOut,
        };
      });

      return {
        ...cart,
        with_sold_out_item: soldoutID.length ? true : false,
        lineItems: updatedPhysicalItems,
      };
    } else {
      return cart;
    }
  } catch (error) {
    return cart;
  }
};

export const CartResolver: CartHandler = {
  addCartItem: async ({
    customerId,
    cartId,
    cartItemId,
    accessToken,
    offer,
    geoLocRegion,
  }: any): Promise<CartHandlerResponse> => {
    const headers = accessToken
      ? { authorization: `Bearer ${accessToken}` }
      : {};
    if (cartId) {
      try {
        const { data } = await vinoFetch.post(
          "/api/add-cart-item",
          {
            offer,
            cartId,
            productId: cartItemId,
            customerId,
          },
          {
            headers,
          }
        );

        const { data: cart } = data;
        const isFreeShipping = await CartResolver.isFreeShippingApplied(cart);

        const cartData = await checkInventoryLevel(
          cartFromBigCommerceCart({
            cart,
            isFreeShipping: isFreeShipping,
            geoLocRegion,
          })
        );

        const cartWithCurrentInventory = await checkInventoryLevel(cartData);
        return {
          cart: cartWithCurrentInventory,
        };
      } catch (error) {
        const { status } = error.response;
        if (error && error?.status === 401) {
          ErrorNotifier.notify(error, errorDataBuilder("FE", status, error));
          return {
            error: new Error("You are not eligible to purchase this item!"),
          };
        }
        // Cart no longer exists.
        if (status === 404) {
          return { cart: null };
        } else if (status === 409) {
          ErrorNotifier.notify(error, errorDataBuilder("FE", status, error));
          return {
            error: new Error(
              "There's a limit of 1 Case per customer for this wine!"
            ),
          };
        } else if (status === 422) {
          ErrorNotifier.notify(error, errorDataBuilder("FE", status, error));
          return { error: new Error("The product is sold-out!") };
        } else {
          ErrorNotifier.notify(error, errorDataBuilder("FE", status, error));
          return { error };
        }
      }
    } else {
      return { cart: null };
    }
  },
  createCart: async ({
    customerId,
    productId,
    offer,
    geoLocRegion,
  }): Promise<CartHandlerResponse> => {
    try {
      const { data } = await vinoFetch.post("/api/create-cart", {
        customerId,
        productId,
        offer,
      });

      const { data: cart } = data;
      const isFreeShipping = await CartResolver.isFreeShippingApplied(cart);

      const cartData = await checkInventoryLevel(
        cartFromBigCommerceCart({
          cart,
          isFreeShipping: isFreeShipping,
          geoLocRegion,
        })
      );

      const cartWithCurrentInventory = await checkInventoryLevel(cartData);
      return {
        cart: cartWithCurrentInventory,
      };
    } catch (error) {
      logError(error.message, { error });
      const statusCode = error.response ? error.response.status : 400;
      if (error?.status === 401) {
        ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
        return {
          error: new Error("You are not eligible to purchase this item!"),
        };
      }

      if (statusCode === 422) {
        ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
        return { error: new Error("The product is sold-out!") };
      }
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return { error };
    }
  },
  updateGuestCartEmail: async ({ cartId, email }): Promise<any> => {
    return await vinoFetch.get("/api/update-guest-cart-email", {
      params: { cartId, email },
    });
  },
  destroyCart: async ({ cartId }): Promise<CartHandlerResponse> => {
    try {
      await vinoFetch.get("/api/destroy-cart", {
        params: { cartId },
      });

      return { cart: null };
    } catch (error) {
      return { error };
    }
  },
  loadCart: async ({
    cartId,
    customerId,
    geoLocRegion,
  }): Promise<CartHandlerResponse> => {
    try {
      const {
        data: { data: cart },
      } = await vinoFetch.get("/api/initialize-cart", {
        params: {
          cartId,
          customerId,
        },
      });
      const isFreeShipping = await CartResolver.isFreeShippingApplied(cart);

      const cartData = await checkInventoryLevel(
        cartFromBigCommerceCart({
          cart,
          isFreeShipping: isFreeShipping,
          geoLocRegion,
        })
      );

      const cartWithCurrentInventory = await checkInventoryLevel(cartData);
      return {
        cart: cartWithCurrentInventory,
      };
    } catch (error) {
      if (error.response?.status === 404) {
        return { cart: null };
      } else {
        const statusCode = error.response ? error.response.status : 400;
        ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
        return { error };
      }
    }
  },
  loadCheckoutUrl: async ({
    accessToken,
    email,
    cartId,
  }): Promise<CheckoutUrlHandlerResponse> => {
    const headers = accessToken
      ? { authorization: `Bearer ${accessToken}` }
      : {};

    const params = accessToken ? { cartId } : { email, cartId };

    try {
      const { data } = await vinoFetch.get(checkoutRedirectUrlApi, {
        headers,
        params,
      });

      const { checkoutUrl } = data.data;
      return { checkoutUrl };
    } catch (error) {
      logError(error.message, { error });
      return { error };
    }
  },
  mergeCarts: async ({
    cartIds,
    cartId,
    geoLocRegion,
  }): Promise<CartHandlerResponse> => {
    try {
      const params: Record<string, string | [string]> = {
        cartIds: [JSON.stringify(cartIds.filter(Boolean))],
      };

      if (cartId) {
        params.cartId = cartId;
      }

      const {
        data: { data: cart },
      } = await vinoFetch.get("/api/merge-carts", {
        params,
      });
      const isFreeShipping = await CartResolver.isFreeShippingApplied(cart);

      const cartData = await checkInventoryLevel(
        cartFromBigCommerceCart({
          cart,
          isFreeShipping: isFreeShipping,
          geoLocRegion,
        })
      );

      const cartWithCurrentInventory = await checkInventoryLevel(cartData);
      return {
        cart: cartWithCurrentInventory,
      };
    } catch (error) {
      logError(error.message, { error });
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return { error };
    }
  },
  checkCart: async ({ cartId, geoLocRegion }): Promise<CartHandlerResponse> => {
    try {
      const {
        data: { data: cart },
      } = await vinoFetch.get("/api/check-cart", {
        params: {
          cartId: cartId,
        },
      });

      const isFreeShipping = await CartResolver.isFreeShippingApplied(cart);

      const cartData = await checkInventoryLevel(
        cartFromBigCommerceCart({
          cart,
          isFreeShipping: isFreeShipping,
          geoLocRegion,
        })
      );

      const cartWithCurrentInventory = await checkInventoryLevel(cartData);
      return {
        cart: cartWithCurrentInventory,
      };
    } catch (error) {
      logError(error.message, { error });
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return { error };
    }
  },
  removeCartItem: async ({
    cartId,
    cartItemId,
    geoLocRegion,
  }): Promise<CartHandlerResponse> => {
    try {
      const { data, status } = await vinoFetch.get("/api/remove-cart-item", {
        params: {
          cartId,
          cartItemId,
        },
      });

      if (status === 204) {
        return { cart: null };
      } else {
        const { data: cart } = data;
        const isFreeShipping = await CartResolver.isFreeShippingApplied(cart);

        const cartData = await checkInventoryLevel(
          cartFromBigCommerceCart({
            cart,
            isFreeShipping: isFreeShipping,
            geoLocRegion,
          })
        );

        const cartWithCurrentInventory = await checkInventoryLevel(cartData);
        return {
          cart: cartWithCurrentInventory,
        };
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return { error };
    }
  },
  updateCartItem: async ({
    cartId,
    cartItemId,
    productId,
    quantity,
    geoLocRegion,
  }): Promise<CartHandlerResponse> => {
    try {
      const { data, status } = await vinoFetch.get("/api/update-cart-item", {
        params: {
          cartId,
          cartItemId,
          productId,
          quantity,
        },
      });

      if (status === 204) {
        return { cart: null };
      } else {
        const { data: cart } = data;
        const isFreeShipping = await CartResolver.isFreeShippingApplied(cart);

        const cartData = await checkInventoryLevel(
          cartFromBigCommerceCart({
            cart,
            isFreeShipping: isFreeShipping,
            geoLocRegion,
          })
        );

        const cartWithCurrentInventory = await checkInventoryLevel(cartData);
        return {
          cart: cartWithCurrentInventory,
        };
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : 400;
      if (statusCode === 422) {
        ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
        return {
          error: new Error(
            "There's a limit of 1 Case per customer for this wine!"
          ),
        };
      }
      return { error };
    }
  },
  addCoupon: async ({
    cartId,
    couponCode,
    accessToken,
    geoLocRegion,
  }): Promise<CartHandlerResponse> => {
    const headers = accessToken
      ? { authorization: `Bearer ${accessToken}` }
      : {};
    if (cartId) {
      try {
        const {
          data: { data: cart },
        } = await vinoFetch.post(
          "/api/add-coupon",
          { cartId, couponCode },
          { headers }
        );
        const isFreeShipping = await CartResolver.isFreeShippingApplied(cart);

        return {
          cart: cartFromBigCommerceCart({
            cart,
            isFreeShipping: isFreeShipping,
            geoLocRegion,
          }),
        };
      } catch (error) {
        const { status } = error;

        // Cart no longer exists.
        if (status === 404) {
          return { cart: null };
        } else {
          ErrorNotifier.notify(error, errorDataBuilder("FE", status, error));
          return { error };
        }
      }
    } else {
      return { cart: null };
    }
  },
  removeCoupon: async ({
    cartId,
    couponCode,
    geoLocRegion,
  }): Promise<CartHandlerResponse> => {
    try {
      const { data, status } = await vinoFetch.get("/api/remove-coupon", {
        params: {
          cartId,
          couponCode,
        },
      });

      if (status === 204) {
        return { cart: null };
      } else {
        const { data: cart } = data;
        const isFreeShipping = await CartResolver.isFreeShippingApplied(cart);

        return {
          cart: cartFromBigCommerceCart({
            cart,
            isFreeShipping: isFreeShipping,
            geoLocRegion,
          }),
        };
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilderV2("FE", statusCode, error));
      return { error };
    }
  },
  getPromotionByCode: async (couponCode): Promise<any> => {
    try {
      const {
        data: { data: promotionData },
      } = await vinoFetch.get("/api/get-promotion-by-code", {
        params: {
          code: couponCode,
        },
      });
      return promotionData;
    } catch (error) {
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      return { error };
    }
  },
  isFreeShippingApplied: async (cart: any) => {
    const { coupons, promotions } = cart;
    if (!coupons && !promotions) return false;

    const hasFreeShippingCoupon =
      coupons && coupons.some((item) => item.coupon_type === "free_shipping");

    if (hasFreeShippingCoupon) return true;

    const couponPromotion = coupons.find(
      (item) => item.coupon_type === "promotion"
    );

    const promotionByCode = couponPromotion
      ? await CartResolver.getPromotionByCode(couponPromotion?.code)
      : null;

    const hasFreeShippingCouponPromotion =
      promotionByCode &&
      promotionByCode.some((p) =>
        p.rules.some(
          (r) => r.action.shipping && r.action.shipping.free_shipping
        )
      );

    if (hasFreeShippingCouponPromotion) return true;

    const appliedPromotions = promotions?.banners?.filter(
      (b) => b.type === PROMOTIONS_BANNER_TYPES.APPLIED
    );

    const retrievedPromotions = await Promise.all(
      appliedPromotions.map(async (promotion) => {
        try {
          const {
            data: { data: promotionData },
          } = await vinoFetch.get("/api/get-single-promotion", {
            params: {
              promotionId: promotion.id,
            },
          });
          return promotionData;
        } catch (error) {
          logError(error.message, { error });

          if (error.response && error.response.status === 404) {
            return null;
          }

          throw error;
        }
      })
    );
    // Return promotion which is only related to free shipping
    return (
      retrievedPromotions.filter((f) => {
        const isFreeshipping = f?.rules?.filter(
          (r) => r?.action?.shipping?.free_shipping
        );
        return isFreeshipping?.length > 0;
      })?.length > 0
    );
  },
};
